import React, { useState } from "react";
import "./toolcss/FileUploader.css"; // Import the CSS file for styling

const FileUploader = ({ handleFileUpload, handleDragOver, handleDrop, svgContent, imageSize, highlightedSvgContent, backgroundColor }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File selected via input:", file.name); // Debugging line
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedFile(reader.result);
      };
      reader.readAsDataURL(file);
      setFileName(file.name);
      handleFileUpload(event);
    }
  };

  const handleDropAndClearFileName = (event) => {
    event.preventDefault(); // Prevent the default browser behavior
    const file = event.dataTransfer.files[0];
    if (file) {
      console.log("File dropped:", file.name); // Debugging line
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedFile(reader.result);
      };
      reader.readAsDataURL(file);
      setFileName(file.name); // Update the state with the dropped file name
      handleDrop(event); // Call the provided handleDrop function
    } else {
      console.log("No valid file dropped."); // Debugging line
    }
  };

  const handleClearUploadedFile = () => {
    setUploadedFile(null);
    setFileName("");
    // Refresh the tool
    window.location.reload();
  };

  return (
    <div
      className="fileupload"
      onDragOver={handleDragOver}
      onDrop={handleDropAndClearFileName}
    >
      {uploadedFile ? (
        <div className="uploaded-image-container">
          <button
            className="close-button"
            onClick={handleClearUploadedFile}
          >
            &times;
          </button>
          <div
            className="tool-image-preview"
            style={{
              height: '500px',
              overflow: 'hidden',
              backgroundColor,
              borderRadius: '15px',
            }}
            dangerouslySetInnerHTML={{ __html: svgContent }} // Render the updated SVG content
          />
          <p>
            <strong>Size:</strong> Width: {imageSize.width}, Height: {imageSize.height}
          </p>
        </div>
      ) : (
        <>
          <input
            type="file"
            accept=".svg"
            onChange={handleFileChange}
            className="file-input"
          />
          <p className="file-upload-text">or</p>
          <p className="file-upload-info">
            Drag and drop an SVG file here for customization
          </p>
          {fileName && (
            <p className="uploaded-file-name">Uploaded File: {fileName}</p>
          )}
        </>
      )}
    </div>
  );
};

export default FileUploader;
