import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ColorHighlighter = ({ colors, temporarySvgContent, setTemporarySvgContent }) => {
  const [highlightedColor, setHighlightedColor] = useState('');

  const handleColorClick = (color) => {
    setHighlightedColor(color);

    try {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(temporarySvgContent, 'image/svg+xml');

      // Find all elements with the selected fill or stroke color
      const elementsToHighlight = svgDoc.querySelectorAll(
        `[fill='${color}'], [stroke='${color}']`
      );

      // Clear previous highlights
      svgDoc.querySelectorAll('[data-highlighted]').forEach((element) => {
        element.removeAttribute('stroke');
        element.removeAttribute('stroke-width');
        element.removeAttribute('data-highlighted');
      });

      // Add highlight to the selected color's elements
      elementsToHighlight.forEach((element) => {
        element.setAttribute('stroke', '#FFD700'); // Highlight color (yellow)
        element.setAttribute('stroke-width', '5'); // Adjust as needed
        element.setAttribute('data-highlighted', 'true'); // Marker for highlighted elements
      });

      // Serialize the updated SVG content
      const serializer = new XMLSerializer();
      const updatedSvgContent = serializer.serializeToString(svgDoc);
      setTemporarySvgContent(updatedSvgContent);
    } catch (error) {
      console.error('Error while highlighting color:', error);
    }
  };

  return (
    <div className="color-highlighter">
      <h3>Click a Color to Highlight</h3>
      <div className="color-boxes" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        {colors.map((color, index) => (
          <div
            key={index}
            className="color-box"
            style={{
              backgroundColor: color,
              border:
                highlightedColor === color ? '3px solid #FFD700' : '1px solid transparent',
              boxShadow:
                highlightedColor === color ? '0 0 10px 2px rgba(255, 215, 0, 0.8)' : 'none',
              cursor: 'pointer',
              width: '30px',
              height: '30px',
              borderRadius: '50%', // Circular color boxes
              transition: 'border 0.3s, box-shadow 0.3s', // Smooth transition for border and glow
            }}
            onClick={() => handleColorClick(color)}
          ></div>
        ))}
      </div>
    </div>
  );
};

ColorHighlighter.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  temporarySvgContent: PropTypes.string.isRequired,
  setTemporarySvgContent: PropTypes.func.isRequired,
};

export default ColorHighlighter;
