import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./navbar.css";

const Navbar = ({ searchInput, setSearchInput }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  const handleLogoClick = () => {
    setSearchInput("");
    navigate("/");
  };

  const handleOpenTool = () => {
    navigate("/tool");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isToolPage = location.pathname === "/tool"; // Check if on the Tool page

  return (
    <nav className={`navbar navbar-light ${isScrolled ? "scrolled" : ""}`}>
      <div className="container-fluid" id="org-nav">
        <div className="navbar-brand" onClick={handleLogoClick}>
          <a href="/" rel="noopener noreferrer" className="logo-link">
            <img
              src="https://the2px.com/wp-content/uploads/2024/10/the2px-logo.svg"
              alt="the2px logo"
              className="navbar-logo"
            />
          </a>
        </div>
        {!isToolPage && (
          <>
            <div className="r-div d-flex align-items-center">
              <div className="search-bar mx-3">
                <input
                  type="text"
                  placeholder="Search by tags..."
                  className="form-control"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                />
                <div className="s-icn">
                  <span className="search-icon">
                    <img
                      src={
                        isInputFocused
                          ? "https://the2px.com/wp-content/uploads/2024/10/right-arrow-svgrepo-com.svg"
                          : "https://the2px.com/wp-content/uploads/2024/10/search-svgrepo-com.svg"
                      }
                      alt="Icon"
                      width="20"
                      height="20"
                      className="yellow-icon"
                    />
                  </span>
                </div>
              </div>
            </div>
            
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
