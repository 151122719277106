import React, { useState } from "react";
import Navbar from "./components/navbar";
import ImageGallery from "./components/ImageGallery";
import Footer from "./components/Footer";
import ImageModal from "./components/ImageModal";
import Tool from "./components/tools/Tool"; // Tool component for tools page
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";

const App = () => {
  const [bgColor, setBgColor] = useState("#fdfdfd");
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState({ src: "", name: "" });

  const location = useLocation();

  const openModal = (imageSrc, imageName) => {
    setSelectedImage({ src: imageSrc, name: imageName });
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage({ src: "", name: "" });
  };

  const downloadSvg = () => {
    console.log("Downloading SVG...");
  };

  const convertSvgToPng = () => {
    console.log("Converting SVG to PNG...");
  };

  const convertSvgToJpeg = () => {
    console.log("Converting SVG to JPEG...");
  };

  return (
    <div className="app">
      {/* Conditionally render Navbar only if not on /tool route */}
      {location.pathname !== "/tool" && (
        <Navbar bgColor={bgColor} setSearchInput={setSearchInput} />
      )}
      <main className="content">
        <Routes>
          {/* Main Image Gallery Route */}
          <Route
            path="/"
            element={
              <ImageGallery
                bgColor={bgColor}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                openModal={openModal}
              />
            }
          />
          {/* Tools Page Route */}
          <Route path="/tool" element={<Tool />} />
        </Routes>
      </main>
      
      <Footer />
      {/* Image Modal Component */}
      <ImageModal
        show={showModal}
        handleClose={closeModal}
        image={selectedImage.src}
        title={selectedImage.name}
        downloadSvg={downloadSvg}
        convertSvgToPng={convertSvgToPng}
        convertSvgToJpeg={convertSvgToJpeg}
      />
    </div>
  );
};

export default App;
