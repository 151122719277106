import React, { useEffect, useState } from 'react'; 
import ColorPicker from './ColorPicker';
import ColorPickerb from './ColorPickerb';


const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;


  return (...args) => {
    if (!lastRan) {
      func(...args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func(...args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};


const ColorPalette = ({ title, colors, setColors, orignalsvg, temporaryContent, onsvgColorChange ,background, onBackgroundColorChange }) => {
  const [backgroundColor, setBackgroundColor] = useState(background);  // Default to transparent
  const [currentColorIndex, setCurrentColorIndex] = useState(null);

  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const [temporarySvgContent, setTemporarySvgContent] = useState(temporaryContent || ""); // Default to an empty string if temporaryContent is not provided
  const [showMoreColors, setShowMoreColors] = useState(false);
  const [visibleCount, setVisibleCount] = useState(7);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [svgContent, setSvgContent] = useState(temporaryContent);

  const [rotated, setRotated] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);


  useEffect(() => {
    if (svgContent) {
      
    }
  }, [svgContent]); 
  
    // This effect runs whenever backgroundColor changes and calls the passed callback prop
    useEffect(() => {
      if (onBackgroundColorChange) {
        onBackgroundColorChange(backgroundColor); 
        
      }
    }, [backgroundColor, onBackgroundColorChange]);
  
    useEffect(() => {
      if (onsvgColorChange) {
          onsvgColorChange(temporarySvgContent);
      }
  }, [temporarySvgContent, onsvgColorChange]);
  


const handleColorChange = (newColor) => {
  if (currentColorIndex !== null) {
    updateColor(currentColorIndex, newColor.hex);
    setImageChanged(true);
  }
};




  const handleBgColorClick = (event) => {
    setShowBgColorPicker(true);
  };

  const handleBgColorChange = (color) => {
    setBackgroundColor(color.hex || color);  // Set the selected color (from the color picker)
    setImageChanged(true); // Mark that the image has changed
    //console.log("Updated SVG content:", backgroundColor);
  };

  const updateColor = throttle((index, newColor) => {
    const updatedColors = [...colors];
    updatedColors[index] = newColor;
    setColors(updatedColors);
    
    const oldColor = colors[index];
    const updatedSvg = temporarySvgContent.replace(new RegExp(oldColor, 'g'), newColor);
    setTemporarySvgContent(updatedSvg);
    // console.log("Updated SVG content:", temporarySvgContent);
  }, 50);
  
  useEffect(() => {
    if (temporaryContent) {
      setTemporarySvgContent(temporaryContent);
    }
  }, [temporaryContent]);
  

  const renderTransparentGrid = () => {
    
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white', // Set the background color to black
          backgroundImage:
            'linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc)',
          backgroundSize: '10px 10px', // Adjust grid size as needed
          backgroundPosition: '0 0, 5px 5px', // Position the grid properly
        }}
      >   
      </div>
    );
  };



    // Update visibleCount based on screen size
    useEffect(() => {
      const updateVisibleCount = () => {
        if (window.innerWidth < 360) {
          setVisibleCount(2);
        } else if (window.innerWidth < 500) {
          setVisibleCount(3);
        } else if (window.innerWidth < 786) {
          setVisibleCount(4);
        } else if (window.innerWidth < 1200) {
          setVisibleCount(5);
        } else {
          setVisibleCount(7);
        }
      };
  
      updateVisibleCount(); // Run initially
      window.addEventListener('resize', updateVisibleCount); // Listen for resize events
  
      // Cleanup listener on component unmount
      return () => window.removeEventListener('resize', updateVisibleCount);
    }, []);
  
    const handleColorCircleClick = (index, event) => {
      setCurrentColorIndex(index);
      // console.log("current color index:", currentColorIndex)
      setShowColorPicker(true);
      
    };
    const applyChangesToOriginal = () => {
      setSvgContent(temporarySvgContent);
      setImageChanged(true);
    };


  const renderColorPickers = () => {
    const visibleColors = showMoreColors ? colors : colors.slice(0, visibleCount);
    return (
      <div className="colors">
        {visibleColors.map((color, index) => (
          <div
            key={index}
            className="color-circle-wrapper"
            style={{ position: 'relative' }}
          >
            <div
              className="color-circle"
              style={{ backgroundColor: color }}
              onClick={(event) => handleColorCircleClick(index, event)}
            />
            {/* Conditionally render ColorPicker next to the clicked color circle */}
            {showColorPicker && currentColorIndex === index && (
              <div
                className="color-picker-wrapper"
                style={{ position: 'absolute', transform: 'translateX(-50%)', zIndex: 1 }}
              >
                <ColorPicker
                  color={colors[currentColorIndex]}
                  onChange={handleColorChange}
                  onClose={() => {
                    applyChangesToOriginal();
                    setShowColorPicker(false);
                    setCurrentColorIndex(null);
                  }}
                />
              </div>
            )}
          </div>
        ))}
        {/* Show More/Less Button */}
        {colors.length > visibleCount && (
          <button
            className="edit-more-colors"
            onClick={() => setShowMoreColors(!showMoreColors)}
            style={{
              background: 'none',
              border: 'none',
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginTop: '7px', // Add some margin for better spacing
            }}
          >
            {showMoreColors ? 'Show Less' : 'Edit More Colors'}
          </button>
        )}
      </div>
      
    );
  };








  const extractColors = (svgString) => {
    if (typeof svgString !== 'string') {
      console.error('Invalid SVG string passed to extractColors:', svgString);
      return [];
    }
  
    const colorRegex = /#([0-9A-Fa-f]{3,6})\b/g;
    const foundColors = new Set(svgString.match(colorRegex) || []);
  
    const hexToLuminance = (hex) => {
      if (hex.length === 4) {
        hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
      }
      const r = parseInt(hex.slice(1, 3), 16) / 255;
      const g = parseInt(hex.slice(3, 5), 16) / 255;
      const b = parseInt(hex.slice(5, 7), 16) / 255;
  
      const lum = (c) => (c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4));
      return 0.2126 * lum(r) + 0.7152 * lum(g) + 0.0722 * lum(b);
    };
  
    return Array.from(foundColors).sort((a, b) => hexToLuminance(a) - hexToLuminance(b));
  };
  
  const resetSvgToOriginal = () => { 
    try {
        // Step 1: Extract original colors from the SVG
        setTemporarySvgContent(orignalsvg); // Reset to the original content
        const extractedColors = extractColors(svgContent); // Pass the correct string
        setColors(extractedColors);         // Main SVG reset

        // Step 3: Reset background color to transparent
        setBackgroundColor('transparent');

        // Step 4: Clear the selected color index
        setCurrentColorIndex(null);

        // Step 5: Trigger rotation animation
        setRotated(true);

        // Animation logic with tick display
        setTimeout(() => {
            setRotated(false);        // Stop rotation animation
            setImageChanged(true);    // Show tick mark

            setTimeout(() => {
                setImageChanged(false); // Hide tick mark after 0.5s
            }, 500);
        }, 600); // Duration for rotation animation

        // Debugging logs
    } catch (error) {
        console.error('Error during resetChanges:', error);
    }
};



  return (

    <div className="content-section">
    <h3> Color's </h3>
    <div className='bg-col'>
    <div className="color-picker-row">
    <div className="bg-color-circle" 
        style={{ 
          backgroundColor: backgroundColor === 'transparent' ? 'none' : backgroundColor 
        }} 
        onClick={handleBgColorClick} 
      >
        {/* Render transparent grid only when backgroundColor is 'transparent' */}
        { backgroundColor === 'transparent' && renderTransparentGrid()}
      </div>

      {/* Background color picker logic */}
      <div className="color-picker-wrapper" style={{ position: 'absolute', marginTop: '40px', transform: 'translateX(-50%)', zIndex: 1 }}>
        {showBgColorPicker && (
          <ColorPickerb
            color={backgroundColor === 'transparent' ? '#ffffff' : backgroundColor}
            onChange={handleBgColorChange}
            onClose={() => setShowBgColorPicker(false)}
          />
        )}
      </div>
        {/* Separator */}
        <div className="separator"></div>
        
        {/* SVG color picker */}
        <div className="colors">
        {renderColorPickers()} {/* Render initial color pickers */}
      </div>
    </div>
  </div>
            <div className="reset-div" style={{ display: imageChanged ? 'block' : 'none' }}>
                <button className="reset-btn" onClick={() => {resetSvgToOriginal()}}>
                  
                      <img 
                        src="https://the2px.com/wp-content/uploads/2024/11/reset-svgrepo-com.svg" 
                        alt="Reset Arrow"
                        style={{ width: '20px', height: '20px' }}
                        className={rotated ? 'rotate-img' : ''}
                      />
                    </button>
                  </div>

                </div>       

  );
};

export default ColorPalette;
