import React, { useState } from 'react';

const DownloadSvg = ({ svgContent, imageTitle, backgroundColor }) => {
  const [selectedFormat, setSelectedFormat] = useState('svg');
  const [resolution, setResolution] = useState('original');
  const [isDownloading, setIsDownloading] = useState(false);
  const [customWidth, setCustomWidth] = useState('');
  const [customHeight, setCustomHeight] = useState('');

  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };

  const getDownloadFileName = (format) => {
    const date = getFormattedDate();
    return `the2px-${imageTitle || 'image'}-${date}.${format}`;
  };

  const handleResolutionChange = (res) => {
    setResolution(res);
  };

  const scaleSvg = (svgContent, resolution, customWidth = null, customHeight = null) => {
    const svgDoc = new DOMParser().parseFromString(svgContent, 'image/svg+xml');
    const svgElement = svgDoc.querySelector('svg');
  
    if (svgElement) {
      if (resolution === 'custom' && customWidth && customHeight) {
        // Set custom resolution provided by user
        svgElement.setAttribute('width', customWidth);
        svgElement.setAttribute('height', customHeight);
      } else if (resolution === '500') {
        svgElement.setAttribute('width', '500');
        svgElement.setAttribute('height', '500');
      } else if (resolution === '1000') {
        svgElement.setAttribute('width', '1000');
        svgElement.setAttribute('height', '1000');
      } else if (resolution === '2000') {
        svgElement.setAttribute('width', '2000');
        svgElement.setAttribute('height', '2000');
      } else if (resolution === 'original') {
        svgElement.removeAttribute('width');
        svgElement.removeAttribute('height');
      }
    }
  
    return new XMLSerializer().serializeToString(svgDoc);
  };
  
  const handleDownload = () => {
    try {
      if (selectedFormat === 'svg') {
        downloadSvg();
      } else if (selectedFormat === 'png') {
        convertSvgToPng();
      } else if (selectedFormat === 'jpeg') {
        convertSvgToJpeg();
      }
      setIsDownloading(true);
  
      // Timeout to revert download state
      const timer = setTimeout(() => {
        setIsDownloading(false);
      }, 2000);
  
      // Cleanup timer if needed
      return () => clearTimeout(timer);
    } catch (error) {
      console.error("Download error:", error);
      setIsDownloading(false); // Reset state in case of failure
    }
  };
  
  const downloadSvg = () => {
    const websiteComment = '<!-- Downloaded from the2px.com -->\n';
  
    // If custom resolution is set, pass customWidth and customHeight to scaleSvg
    const updatedSvgContent = scaleSvg(svgContent, resolution, customWidth, customHeight);
  
    // Check if updatedSvgContent starts with an XML declaration
    const xmlDeclarationMatch = updatedSvgContent.match(/^<\?xml.*?\?>\s*/);
    let finalSvgContent;
  
    if (xmlDeclarationMatch) {
      const xmlDeclaration = xmlDeclarationMatch[0];
      finalSvgContent = xmlDeclaration + websiteComment + updatedSvgContent.slice(xmlDeclaration.length);
    } else {
      finalSvgContent = websiteComment + updatedSvgContent;
    }
  
    const svgBlob = new Blob([finalSvgContent], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);
  
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', getDownloadFileName('svg')); // Ensure this function returns the appropriate name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    URL.revokeObjectURL(url);
  };
  
  const convertSvgToPng = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const img = new Image();
    img.onload = () => {
      let width, height;
  
      if (resolution === 'original') {
        const svgElement = new DOMParser().parseFromString(svgContent, "image/svg+xml").documentElement;
        width = svgElement.getAttribute('width') ? parseInt(svgElement.getAttribute('width'), 10) : 500; // Default width
        height = svgElement.getAttribute('height') ? parseInt(svgElement.getAttribute('height'), 10) : 500; // Default height
      } else if (resolution === 'custom' && customWidth && customHeight) {
        width = customWidth;
        height = customHeight;
      } else {
        const size = parseInt(resolution, 10);
        width = height = size; // For fixed resolutions, width and height will be the same
      }
  
      // Set canvas width and height
      canvas.width = width;
      canvas.height = height;
  
      // Add background color if one is selected
      if (backgroundColor !== 'transparent' && selectedFormat === 'png') {
        ctx.fillStyle = backgroundColor; // Use the selected background color
        ctx.fillRect(0, 0, width, height); // Fill the entire canvas with the background color
      }
  
      // Calculate aspect ratio
      const aspectRatio = img.width / img.height;
  
      // Adjusting the drawImage parameters to keep aspect ratio
      if (aspectRatio > 1) {
        ctx.drawImage(img, 0, (height - (width / aspectRatio)) / 2, width, width / aspectRatio);
      } else {
        ctx.drawImage(img, (width - (height * aspectRatio)) / 2, 0, height * aspectRatio, height);
      }
  
      const pngUrl = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = pngUrl;
      a.download = getDownloadFileName('png');
      a.click();
    };
  
    img.onerror = () => {
      console.error("Image failed to load for PNG conversion.");
    };
  
    img.src = `data:image/svg+xml;base64,${btoa(svgContent)}`;
  };
  
  const convertSvgToJpeg = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const img = new Image();
    img.onload = () => {
      let width, height;
  
      if (resolution === 'original') {
        const svgElement = new DOMParser().parseFromString(svgContent, "image/svg+xml").documentElement;
        width = svgElement.getAttribute('width') ? parseInt(svgElement.getAttribute('width'), 10) : 500; // Default width
        height = svgElement.getAttribute('height') ? parseInt(svgElement.getAttribute('height'), 10) : 500; // Default height
      } else if (resolution === 'custom' && customWidth && customHeight) {
        width = customWidth;
        height = customHeight;
      } else {
        const size = parseInt(resolution, 10);
        width = height = size; // For fixed resolutions, width and height will be the same
      }
  
      // Set canvas width and height
      canvas.width = width;
      canvas.height = height;
  
      // If background color is transparent, use white instead
      const effectiveBackgroundColor = backgroundColor === 'transparent' ? 'white' : backgroundColor;
  
      // Fill background with selected or white color if transparent
      ctx.fillStyle = effectiveBackgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
  
      // Calculate aspect ratio
      const aspectRatio = img.width / img.height;
  
      // Adjusting the drawImage parameters to keep aspect ratio
      if (aspectRatio > 1) {
        ctx.drawImage(img, 0, (height - (width / aspectRatio)) / 2, width, width / aspectRatio);
      } else {
        ctx.drawImage(img, (width - (height * aspectRatio)) / 2, 0, height * aspectRatio, height);
      }
  
      const jpegUrl = canvas.toDataURL('image/jpeg', 1.0);
      const a = document.createElement('a');
      a.href = jpegUrl;
      a.download = getDownloadFileName('jpeg');
      a.click();
    };
  
    img.onerror = () => {
      console.error("Image failed to load for JPEG conversion.");
    };
  
    img.src = `data:image/svg+xml;base64,${btoa(svgContent)}`;
  };

  return (
    <div className="download-container">
      <div className="format-selection">
      <div className="res-sec">
              <h3 style={{ paddingLeft: '5%' }}>
                  <span>Format</span>
                  </h3>
                  <div className="b-35">
                    <ul className='b-35l'>
                    <li>
                      <a 
                        href="#svg" 
                        className={`button-35 ${selectedFormat === 'svg' ? 'active' : ''}`} 
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedFormat('svg');
                        }}
                      >
                        SVG
                      </a>
                    </li>
                    <li>
                      <a 
                        href="#png" 
                        className={`button-35 ${selectedFormat === 'png' ? 'active' : ''}`} 
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedFormat('png');
                        }}
                      >
                        PNG
                      </a>
                    </li>
                    <li>
                      <a 
                        href="#jpeg" 
                        className={`button-35 ${selectedFormat === 'jpeg' ? 'active' : ''}`} 
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedFormat('jpeg');
                        }}
                      >
                        JPEG
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
      <div className="format-selection">
                <h3 style={{ paddingLeft: '5%' }}>
                  <span>Resolution</span>
                </h3>
                <div className="b-35">
                  <ul className="b-352">
                    <li>
                      <a
                        href="#1000"
                        className={`button-35 ${resolution === '1000' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleResolutionChange('1000');
                        }}
                      >
                        1000 x 1000
                      </a>
                    </li>
                    <li>
                      <a
                        href="#original"
                        className={`button-35 ${resolution === 'original' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleResolutionChange('original');
                        }}
                      >
                        1920 x 1356
                      </a>
                    </li>
                    <li id="lst-r">
                      <a
                        href="#2000"
                        className={`button-35 ${resolution === '2000' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleResolutionChange('2000');
                        }}
                      >
                        2000 x 2000
                      </a>
                    </li>
                    <li>
                      <a
                        href="#custom"
                        className={`button-35 ${resolution.includes('x') ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setCustomWidth(''); // Clear previous inputs
                          setCustomHeight('');
                          setResolution('custom');
                        }}
                        style={resolution === 'custom' ? { border: '1px solid #c1272d' } : {}}
                      >
                        Custom
                      </a>
                    </li>
                  </ul>

                  {resolution === 'custom' && (
                    <div
                      className="res-change"
                      style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                    >
                      <input
                        type="number"
                        placeholder="Width"
                        value={customWidth}
                        onChange={(e) => setCustomWidth(e.target.value)}
                        style={{
                          padding: '5px',
                          width: '80px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                        }}
                      />
                      <span style={{ fontSize: '1rem' }}>x</span>
                      <input
                        type="number"
                        placeholder="Height"
                        value={customHeight}
                        onChange={(e) => setCustomHeight(e.target.value)}
                        style={{
                          padding: '5px',
                          width: '80px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="download-section">
                  <button className="button-29" onClick={handleDownload}>
                    Download
                    <img
                      src={
                        isDownloading
                          ? 'https://the2px.com/wp-content/uploads/2024/10/save-1.svg'
                          : 'https://the2px.com/wp-content/uploads/2024/10/download-svgrepo-com.svg'
                      }
                      alt={isDownloading ? 'Save Icon' : 'Download Icon'}
                      style={{
                        marginLeft: '10px',
                        height: '20px',
                        width: '20px',
                        filter: 'invert(100%)',
                      }}
                    />
                  </button>
                </div>
                </div>
                </div>
  );
};

export default DownloadSvg;
