import React, { useState, useEffect, useCallback } from 'react';
import ToolNavbar from './ToolNavbar'; // Import ToolNavbar
import FileUploader from './FileUploader';
import ColorSelector from './ColorSelector';
import ColorPalette from './ColorPalette';
import DownloadSvg from './DownloadSvg';
import ColorHighlighter from './ColorHighlighter'; // Import the new component
import "./Tool.css";




const Tool = () => {
  const [svgContent, setSvgContent] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('transparent');  // Default to transparent
  const [temporarySvgContent, setTemporarySvgContent] = useState('');
  const [imageSize, setImageSize] = useState({ width: null, height: null });
  const [colors, setColors] = useState([]);

  const [selectedColor, setSelectedColor] = useState('');
  const [colorSelectorOpen, setColorSelectorOpen] = useState(false);

  useEffect(() => {
    if (colors) {
     // console.log("Updated temp SVG content:", temporarySvgContent);
    }
  }, [colors]); // Logs whenever highlightedSvgContent changes
  


 

const extractColors = (svgString) => {
  if (typeof svgString !== 'string') {
    console.error('Invalid SVG string passed to extractColors:', svgString);
    return [];
  }

  const colorRegex = /#([0-9A-Fa-f]{3,6})\b/g;
  const foundColors = new Set(svgString.match(colorRegex) || []);

  const hexToLuminance = (hex) => {
    if (hex.length === 4) {
      hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
    }
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    const lum = (c) => (c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4));
    return 0.2126 * lum(r) + 0.7152 * lum(g) + 0.0722 * lum(b);
  };

  return Array.from(foundColors).sort((a, b) => hexToLuminance(a) - hexToLuminance(b));
};

const handleBackgroundColorChange = useCallback((newColor) => {
  setBackgroundColor(newColor);
}, []);

const handlesvgColorChange = (temporarySvgContent) => {
  setTemporarySvgContent(temporarySvgContent);
  //console.log("Color changes saved.", temporarySvgContent)
};
  
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    processFile(file);
  }
};

const handleDragOver = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const handleDrop = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const file = event.dataTransfer.files[0];
  if (file) {
    processFile(file);
  }
};

const processFile = (file) => {
  const reader = new FileReader();

  // Clear state before processing the new file
  setSvgContent('');
  setTemporarySvgContent('');
  setImageSize({ width: null, height: null });
  setColors([]);
  setSelectedColor('');
  setBackgroundColor('transparent');

  reader.onload = (e) => {
    const svgString = e.target.result; // Correctly get the SVG content as a string
    setSvgContent(svgString); // Save the SVG content to state
    setTemporarySvgContent(svgString);

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');

    const svgElement = svgDoc.querySelector('svg');
    let width = svgElement?.getAttribute('width');
    let height = svgElement?.getAttribute('height');

    if (!width || !height) {
      const viewBox = svgElement?.getAttribute('viewBox');
      if (viewBox) {
        const [, , vbWidth, vbHeight] = viewBox.split(' ').map(parseFloat);
        width = vbWidth ? `${vbWidth}px` : 'Unknown';
        height = vbHeight ? `${vbHeight}px` : 'Unknown';
      } else {
        width = 'Not specified';
        height = 'Not specified';
      }
    }

    setImageSize({ width, height });

    // Extract and set colors
    const extractedColors = extractColors(svgString); // Pass the correct string
    setColors(extractedColors);
  };

  reader.readAsText(file);
};
  



  return (
    <div className='toolpagenav'>
      <ToolNavbar /> {/* Add ToolNavbar here */}
    
      <div className='toolpage'>
        <h1>SVG Color Extractor</h1>
        <FileUploader 
          handleFileUpload={handleFileUpload} 
          handleDragOver={handleDragOver}
          handleDrop={handleDrop} 
          svgContent={temporarySvgContent}       
          imageSize={imageSize}
          temporaryContent={temporarySvgContent}
          backgroundColor={backgroundColor}
        />
      </div>

      <div className="other-content">
  {/* Add the DownloadSvg Component */}
  {temporarySvgContent && (
    <div className="download-section">
      <DownloadSvg svgContent={temporarySvgContent} backgroundColor={backgroundColor} />
    </div>
  )}
  
  <div className="separator2"></div> {/* Separator between sections */}

  <div className="color-combination">
    {colors.length > 0 && (
      <>
        <ColorPalette
          title="All Colors"
          colors={colors}
          setColors={setColors}
          orignalsvg={svgContent}
          temporaryContent={temporarySvgContent}
          onsvgColorChange={handlesvgColorChange}
          background={backgroundColor}
          onBackgroundColorChange={handleBackgroundColorChange}
        />
        <ColorHighlighter
          colors={colors}
          temporarySvgContent={temporarySvgContent}
          setTemporarySvgContent={setTemporarySvgContent}
        />
      </>
    )}
  </div>
</div>

</div>
);
};

export default Tool;
