import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Toolnavbar.css";

const ToolNavbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 
  return (
    <nav className={`navbar navbar-light ${isScrolled ? "scrolled" : ""}`}>
      <div className="container-fluid" id="org-nav">
        <div className="navbar-brand" onClick={handleLogoClick}>
          <a href="/" rel="noopener noreferrer" className="logo-link">
            <img
              src="https://the2px.com/wp-content/uploads/2024/10/the2px-logo.svg"
              alt="the2px logo"
              className="navbar-logo"
            />
          </a>
        </div>

      </div>
    </nav>
  );
};

export default ToolNavbar;
